import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const TimelineImage = styled.div`
  width: 432px;
  flex: 0 0 auto;

  img {
    margin-top: ${spacing.space32};
  }

  p {
    line-height: 36px;
    font-style: italic;
    margin-top: ${spacing.space24};
    margin-bottom: ${spacing.space24};

    @media (max-width: ${breakpoints.s}) {
      line-height: ${spacing.space32};
    }
  }

  &:first-child {
    margin-left: calc((100vw - 1120px) / 2);

    @media (max-width: ${breakpoints.xl}) {
      margin-left: ${spacing.space48};
    }

    @media (max-width: ${breakpoints.l}) {
      margin-left: ${spacing.space24};
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100vw;
    scroll-snap-align: start;
  }
`;
