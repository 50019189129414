import styled from 'styled-components';
import { breakpoints, spacing } from '@naf/theme';

export const TimelineText = styled.div`
  width: 656px;
  flex: 0 0 auto;

  h2 {
    margin-top: ${spacing.space24};
    margin-bottom: ${spacing.space24};
  }

  p {
    margin-top: ${spacing.space32};
    margin-right: ${spacing.space120};
    font-size: 20px;
    line-height: 32px;
  }

  ul {
    margin-right: ${spacing.space120};
  }

  ol {
    margin-right: ${spacing.space120};
  }

  &:first-child {
    margin-left: calc((100vw - 1120px) / 2);

    @media (max-width: ${breakpoints.xl}) {
      margin-left: ${spacing.space48};
    }

    @media (max-width: ${breakpoints.l}) {
      margin-left: ${spacing.space24};
    }
  }

  @media (max-width: ${breakpoints.m}) {
    width: 100vw;
    scroll-snap-align: start;
  }
`;
